import { lazy, Suspense } from 'react';
import { Layout as DashboardLayout, MainContent } from '@kitalulus/web-ui-kit';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from '~/components/Layout';
import LoadingPage from '~/components/LoadingPage';
import { useRemoteConfigValue } from '~/hooks/firebase/use-remote-config';

import DashboardPrivacy from '~/pages/Privacy/DashboardPrivacy';
import { ACCOUNT_TABS } from '~/utils/constants';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';

const Home = lazy(() => import('~/pages/Home'));
const VacancyList = lazy(() => import('~/pages/VacancyList'));
const CreateVacancy = lazy(() => import('~/pages/CreateVacancy'));
const UpdateVacancy = lazy(() => import('~/pages/UpdateVacancy'));
const RepostVacancy = lazy(() => import('~/pages/RepostVacancy'));
const CopyVacancy = lazy(() => import('~/pages/CopyVacancy'));
const VacancyDetail = lazy(() => import('~/pages/VacancyDetail'));
const CandidateRecommendationDetail = lazy(
  () => import('~/pages/CandidateRecommendationDetail'),
);
const CandidateRecommendationList = lazy(
  () => import('~/pages/CandidateRecommendationList'),
);
const CandidateRecommendationVacancy = lazy(
  () => import('~/pages/CandidateRecommendationVacancy'),
);

const Account = lazy(() => import('~/pages/Account'));
const ChangePassword = lazy(() => import('~/pages/AccountChangePassword'));
const Profile = lazy(() => import('~/pages/AccountProfile'));
const AccountHr = lazy(() => import('~/pages/AccountHr'));
const AccountPackageDetail = lazy(() => import('~/pages/AccountPackageDetail'));
const AccountPackageDetailV2 = lazy(
  () => import('~/pages/AccountPackageDetailV2'),
);

const Applicants = lazy(() => import('~/pages/Applicants'));
const ApplicantDetail = lazy(() => import('~/pages/ApplicantDetail'));

const DashboardRoutes = () => {
  const isMonetizationActive = useRemoteConfigValue('ff_monetization');
  const isCandidateRecommendationActive = useRemoteConfigValue(
    'ff_candidate_recommendation',
  );
  const isPayPerPostActive = useFeatureFlag('pay_per_post');

  return (
    <DashboardLayout>
      <Layout>
        <Suspense
          fallback={
            <MainContent sx={{ main: { height: '100%' } }} height="100vh">
              <LoadingPage height="100%" width="100%" variant="inline" />
            </MainContent>
          }
        >
          <Routes>
            <Route path="dashboard" element={<Home />} />
            <Route path="vacancy" element={<VacancyList />} />
            <Route path="vacancy/create" element={<CreateVacancy />} />
            <Route path="vacancy/create/:id" element={<CopyVacancy />} />
            <Route path="vacancy/:id" element={<VacancyDetail />} />
            <Route path="vacancy/:id/update" element={<UpdateVacancy />} />
            <Route path="vacancy/:id/repost" element={<RepostVacancy />} />
            <Route path="applicants" element={<Applicants />} />
            <Route path="applicants/detail" element={<ApplicantDetail />} />
            {isCandidateRecommendationActive && (
              <>
                <Route
                  path="candidate-recommendation"
                  element={<CandidateRecommendationVacancy />}
                />
                <Route
                  path="candidate-recommendation/list"
                  element={<CandidateRecommendationList />}
                />
                <Route
                  path="candidate-recommendation/list/:id"
                  element={<CandidateRecommendationList />}
                />
                <Route
                  path="candidate-recommendation/list/:id/detail/:candidateID"
                  element={<CandidateRecommendationDetail />}
                />
              </>
            )}
            <Route path="account" element={<Account />}>
              <Route index element={<Profile />} />
              <Route
                path={ACCOUNT_TABS.PROFILE.toLowerCase()}
                element={<Profile />}
              />
              <Route
                path={ACCOUNT_TABS.USER_PROFILE.toLowerCase()}
                element={<AccountHr />}
              />
              <Route
                path={ACCOUNT_TABS.CHANGE_PASSWORD.toLowerCase()}
                element={<ChangePassword />}
              />
              {isMonetizationActive && (
                <Route
                  path={ACCOUNT_TABS.PACKAGE_DETAIL.toLowerCase()}
                  element={
                    isPayPerPostActive ? (
                      <AccountPackageDetailV2 />
                    ) : (
                      <AccountPackageDetail />
                    )
                  }
                />
              )}
              {/* <Route
                path={ACCOUNT_TABS.NOTIFICATION.toLowerCase()}
                element={<AccountNotification />}
              /> */}
            </Route>
            <Route path="privacy" element={<DashboardPrivacy />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Suspense>
      </Layout>
    </DashboardLayout>
  );
};

export default DashboardRoutes;
