import { Box, BoxProps } from '@kitalulus/web-ui-kit';
import { forwardRef, memo } from 'react';

export type TruncateTextProps = BoxProps & {
  noOfLines?: number;
  maxCharacterLength?: number;
};

const TruncateText = forwardRef<HTMLDivElement, TruncateTextProps>(
  ({ noOfLines, maxCharacterLength, ...props }, ref) => {
    let truncateSxProps: BoxProps['sx'] = {};
    if (noOfLines) {
      truncateSxProps = {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: noOfLines,
        wordBreak: 'break-all',
      };
    }

    if (maxCharacterLength) {
      truncateSxProps = {
        ...truncateSxProps,
        maxWidth: `${maxCharacterLength}ch`,
      };
    }

    return (
      <Box ref={ref} {...props} sx={{ ...truncateSxProps, ...props.sx }} />
    );
  },
);

export default memo(TruncateText);
