import { useAppSelector } from '~/hooks/use-store';
import ApplicantFeedbackDialog from './ApplicantFeedbackDialog';
import CandidateRecommendationRejectConfirmDialog from './CandidateRecommendationRejectConfirmDialog';
import CandidateRecommendationVerificationDialog from './CandidateRecommendationVerificationDialog';
import LogoutConfirmationDialog from './LogoutConfirmationDialog';
import ProfilePreviewDialog from './ProfilePreviewDialog';
import RemarksDialog from './RemarksDialog';
import SubscriptionPlanConfirmationDialog from './SubscriptionPlanConfirmationDialog';
import SubscriptionPlanDialog from './SubscriptionPlanDialog';
import VacancyEthicCodeDialog from './VacancyEthicCodeDialog';
import VacancyEthicCodeSubmitDialog from './VacancyEthicCodeSubmitDialog';
import VacancyHighlightDialog from './VacancyHighlightDialog';
import VacancyUnHighlightDialog from './VacancyUnHighlightDialog';
import VacancyHighlightQuotaExceededDialog from './VacancyHighlightQuotaExceededDialog';
import VacancyOnReviewDialog from './VacancyOnReviewDialog';
import VacancyChangeStateDialog from './VacancyChangeStateDialog';
import CandidateRecommendationInviteDialog from './CandidateRecommendationInviteDialog';
import CandidateRecommendationEmailPreviewDialog from './CandidateRecommendationEmailPreviewDialog';
import VacancyPreviewDialog from './VacancyPreviewDialog';
import ApplicantAcceptDialog from './ApplicantAcceptDialog';
import ApplicantRejectDialog from './ApplicantRejectDialog';
import ApplicantInterviewDialog from './ApplicantInterviewDialog';
import ApplicantStatusLegendDialog from './ApplicantStatusLegendDialog';
import VacancyClosedSurveyDialog from './VacancyClosedSurveyDialog';
import VacancyRepostDialog from './VacancyRepostDialog';
import VacancyShareDialog from './VacancyShareDialog';
import ConfirmDialogV2 from '../Dialogs/ConfirmDialogV2';
import { useFeatureFlag } from '~/hooks/firebase/use-feature-flag';
import SubscriptionPlanDialogV2 from './SubscriptionPlanDialogV2';
import SubscriptionPlanConfirmationDialogV2 from './SubscriptionPlanConfirmationDialogV2';

const GlobalDialogs = () => {
  const isPayPerPostActive = useFeatureFlag('pay_per_post');
  const {
    applicantAcceptDialog,
    applicantInterviewDialog,
    applicantRejectDialog,
    applicantStatusLegendDialog,
    applicantFeedbackDialog,
    candidateRecommendationEmailPreviewDialog,
    candidateRecommendationInviteDialog,
    candidateRecommendationRejectConfirmDialog,
    candidateRecommendationVerificationDialog,
    logoutConfirmationDialog,
    profilePreviewDialog,
    remarksDialog,
    subscriptionPlanConfirmationDialog,
    subscriptionPlanConfirmationDialogV2,
    subscriptionPlanDialog,
    vacancyEthicCodeDialog,
    vacancyEthicCodeSubmitDialog,
    vacancyHighlightDialog,
    vacancyUnHighlightDialog,
    vacancyHighlightQuotaExceededDialog,
    vacancyOnReviewDialog,
    vacancyChangeStateDialog,
    vacancyPreviewDialog,
    vacancyClosedSurveyDialog,
    vacancyRepostDialog,
    vacancyShareDialog,
    confirmDialogV2,
  } = useAppSelector((state) => state.dialogs);

  return (
    <>
      {!!applicantAcceptDialog?.open && (
        <ApplicantAcceptDialog
          {...applicantAcceptDialog}
          open={!!applicantAcceptDialog?.open}
        />
      )}
      {!!applicantInterviewDialog?.open && (
        <ApplicantInterviewDialog
          {...applicantInterviewDialog}
          open={!!applicantInterviewDialog?.open}
        />
      )}
      {!!applicantRejectDialog?.open && (
        <ApplicantRejectDialog
          {...applicantRejectDialog}
          open={!!applicantRejectDialog?.open}
        />
      )}
      {!!applicantStatusLegendDialog?.open && (
        <ApplicantStatusLegendDialog
          {...applicantStatusLegendDialog}
          open={!!applicantStatusLegendDialog?.open}
        />
      )}
      {!!applicantFeedbackDialog?.open && (
        <ApplicantFeedbackDialog
          {...applicantFeedbackDialog}
          open={!!applicantFeedbackDialog?.open}
        />
      )}
      {!!candidateRecommendationVerificationDialog?.open && (
        <CandidateRecommendationVerificationDialog
          {...candidateRecommendationVerificationDialog}
          open={!!candidateRecommendationVerificationDialog.open}
        />
      )}
      {!!candidateRecommendationInviteDialog?.open && (
        <CandidateRecommendationInviteDialog
          {...candidateRecommendationInviteDialog}
          open={!!candidateRecommendationInviteDialog?.open}
        />
      )}
      {!!candidateRecommendationEmailPreviewDialog?.open && (
        <CandidateRecommendationEmailPreviewDialog
          {...candidateRecommendationEmailPreviewDialog}
          open={!!candidateRecommendationEmailPreviewDialog?.open}
        />
      )}
      {!!candidateRecommendationRejectConfirmDialog?.open && (
        <CandidateRecommendationRejectConfirmDialog
          {...candidateRecommendationRejectConfirmDialog}
          open={!!candidateRecommendationRejectConfirmDialog?.open}
        />
      )}
      {!!logoutConfirmationDialog?.open && (
        <LogoutConfirmationDialog
          {...LogoutConfirmationDialog}
          open={!!logoutConfirmationDialog?.open}
        />
      )}
      {!!profilePreviewDialog?.open && (
        <ProfilePreviewDialog
          {...profilePreviewDialog}
          open={!!profilePreviewDialog?.open}
        />
      )}
      {!!remarksDialog?.open && (
        <RemarksDialog {...remarksDialog} open={remarksDialog.open} />
      )}
      {!!subscriptionPlanConfirmationDialogV2?.open && (
        <SubscriptionPlanConfirmationDialogV2
          {...subscriptionPlanConfirmationDialogV2}
          open={true}
        />
      )}
      {!!subscriptionPlanConfirmationDialog?.open && (
        <SubscriptionPlanConfirmationDialog
          {...subscriptionPlanConfirmationDialog}
          open={true}
        />
      )}
      {!!subscriptionPlanDialog?.open &&
        (isPayPerPostActive ? (
          <SubscriptionPlanDialogV2 {...subscriptionPlanDialog} open={true} />
        ) : (
          <SubscriptionPlanDialog {...subscriptionPlanDialog} open={true} />
        ))}
      {!!vacancyEthicCodeDialog?.open && (
        <VacancyEthicCodeDialog
          {...vacancyEthicCodeDialog}
          open={!!vacancyEthicCodeDialog?.open}
        />
      )}
      {!!vacancyEthicCodeSubmitDialog?.open && (
        <VacancyEthicCodeSubmitDialog
          {...vacancyEthicCodeSubmitDialog}
          open={!!vacancyEthicCodeSubmitDialog?.open}
        />
      )}
      {!!vacancyHighlightDialog?.open && (
        <VacancyHighlightDialog
          {...vacancyHighlightDialog}
          open={!!vacancyHighlightDialog?.open}
        />
      )}
      {!!vacancyUnHighlightDialog?.open && (
        <VacancyUnHighlightDialog
          {...vacancyUnHighlightDialog}
          open={!!vacancyUnHighlightDialog?.open}
        />
      )}
      {!!vacancyHighlightQuotaExceededDialog?.open && (
        <VacancyHighlightQuotaExceededDialog
          open={!!vacancyHighlightQuotaExceededDialog?.open}
        />
      )}
      {!!vacancyOnReviewDialog?.open && (
        <VacancyOnReviewDialog
          {...vacancyOnReviewDialog}
          open={!!vacancyOnReviewDialog?.open}
        />
      )}
      {!!vacancyPreviewDialog?.open && (
        <VacancyPreviewDialog
          {...vacancyPreviewDialog}
          open={!!vacancyPreviewDialog?.open}
        />
      )}
      {!!vacancyChangeStateDialog?.open && (
        <VacancyChangeStateDialog
          {...vacancyChangeStateDialog}
          open={!!vacancyChangeStateDialog?.open}
        />
      )}
      {!!vacancyClosedSurveyDialog?.open && (
        <VacancyClosedSurveyDialog
          {...vacancyClosedSurveyDialog}
          open={!!vacancyClosedSurveyDialog?.open}
        />
      )}
      {!!vacancyRepostDialog?.open && (
        <VacancyRepostDialog
          {...vacancyRepostDialog}
          open={!!vacancyRepostDialog?.open}
        />
      )}
      {!!vacancyShareDialog?.open && (
        <VacancyShareDialog
          {...vacancyShareDialog}
          open={!!vacancyShareDialog?.open}
        />
      )}
      {!!confirmDialogV2?.open && (
        <ConfirmDialogV2 {...confirmDialogV2} open={!!confirmDialogV2?.open} />
      )}
    </>
  );
};

export default GlobalDialogs;
