import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const version = import.meta.env.NPM_PACKAGE_VERSION;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      // transaltion file path
      loadPath: '/i18n/{{ns}}/{{lng}}.json',
      queryStringParams: { v: version },
    },
    // default language
    fallbackLng: 'id',
    // disable debug on production
    // debug: appConfig.isDevelopment, // Uncomment if need i18n logging
    // have a common namespace used around the full app
    // add other name space if you have multiple apps with different namespaces
    ns: [
      'account',
      'applicant',
      'applicant-detail',
      'auth',
      'business-error',
      'candidate-recommendation',
      'candidate-recommendation-detail',
      'change-password',
      'coach-mark',
      'common',
      'company',
      'dashboard',
      'disconnected',
      'fraud',
      'privacy',
      'restriction',
      'subscription',
      'vacancy',
      'vacancy-detail',
      'vacancy-ethic-code',
      'vacancy-form',
    ],
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
  });

export default i18n;
