export const emailMaxLength = 100;
export const emailPattern = new RegExp(/^[\w+.-]+@([\w-]+\.).[\w.-]{1,24}$/g);

export const passwordMinLength = 8;
export const passwordMaxLength = 50;
export const passwordUpperCasePattern = new RegExp(/[A-Z]+/);
export const passwordDigitPattern = new RegExp(/\d+/);

export const positionNameMinLength = 4;
export const positionNameMaxLength = 100;
export const positionDescriptionMaxLength = 2000;
export const positionCountMin = 1;
export const positionCountMax = 200;
export const salaryMin = 100000;
export const salaryMax = 100000000;
export const applicantQualificationMaxLength = 1000;
export const skillRequirementMin = 1;
export const skillRequirementMax = 20;
export const benefitMax = 10;
export const filterJobFunctionMax = 7;
export const filterSkillMax = 7;
export const applicantAgeMin = 18;
export const applicantAgeMax = 65;
export const applicantMinimumExperienceMin = 1;
export const applicantMinimumExperienceMax = 20;
export const vacancyScreeningQuestionMax = 8;
export const industryMax = 3;
export const docCertMax = 20;
export const docCertInputMin = 2;
export const docCertInputMax = 45;

export const profileFilterEducationInstitutionsMax = 10;

export const phoneNumberMinLength = 9;
export const phoneNumberMaxLength = 16;
export const phoneNumberWithoutLeadingZeroPattern = /^[1-9]+\d*$/;
export const phoneNumberWithoutLeading62Pattern = /^(?!62)\d/;

export const signupNameMaxLength = 100;

export const onlyNumberPattern = /^-?\d*$/;
export const onlyPositiveNumberPattern = /^\d*$/;
export const emojiPattern = /\p{Extended_Pictographic}/gu;
export const positionNamePattern = /[^A-Za-z0-9./,&()-\s]/g;

export const maxSelectedRejectReasons = 3;

export const websitePattern = /^[a-zA-Z0-9_\-/.]*$/;
export const websitePatternWithoutWWW =
  /^([a-z\d.-]+)\.([a-z.]{2,6})(\/[\w.-]+)?\/?$/i;
export const websitePatternWithWWW =
  /^www\.([a-z\d.-]+)\.([a-z.]{2,6})(\/[\w.-]+)?\/?$/i;
export const tiktokPattern =
  /^(?:www\.)?tiktok\.com\/?@([a-zA-Z0-9.]{0,23}\w)$/;
