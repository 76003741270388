import mixpanel, { Config } from 'mixpanel-browser';

import { appConfig } from '~/config/app';
import {
  MixpanelAccountTabProperties,
  MixpanelApplicantAcceptBulkProperties,
  MixpanelApplicantAcceptSingleProperties,
  MixpanelApplicantContactProperties,
  MixpanelApplicantDetailTabProperties,
  MixpanelApplicantDownloadCVProperties,
  MixpanelApplicantDownloadProfileProperties,
  MixpanelApplicantFilterProperties,
  MixpanelApplicantPreviewProperties,
  MixpanelApplicantProceedBulkProperties,
  MixpanelApplicantProceedSingleProperties,
  MixpanelApplicantRecommendationFeedbackProperties,
  MixpanelApplicantRejectBulkProperties,
  MixpanelApplicantRejectSingleProperties,
  MixpanelApplicantSearchProperties,
  MixpanelApplicantTabProperties,
  MixpanelCandidateActionConfirmProperties,
  MixpanelCandidateActionProperties,
  MixpanelCandidateSelectProperties,
  MixpanelCandidateVacancySelectProperties,
  MixpanelMenuSidebarSelectProperties,
  MixpanelOnboardingSelfRegistrationSubmitProperties,
  MixpanelPremiumOfferingProperties,
  MixpanelPremiumOfferingSelectProperties,
  MixpanelUserProperties,
  MixpanelVacancyCreateSubmitProperties,
  MixpanelVacancyUpdateProperties,
  MixpanelVacancyUpdateStatusProperties,
  MixpanelVacancyHighlightCancelProperties,
  MixpanelVacancyHighlightSelectProperties,
  MixpanelVacancyHighlightSubmitProperties,
  MixpanelVacancySeePolicyProperties,
  MixpanelVacancySeePreviewProperties,
  MixpanelVacancyUnhighlightCancelProperties,
  MixpanelVacancyUnhighlightSelectProperties,
  MixpanelVacancyUnhighlightSubmitProperties,
  MixpanelLogoutProperties,
  MixpanelApplicantBulkErrorProcessProperties,
  MixpanelApplicantClosePopoverProperties,
  MixpanelApplicantSupportLinkProperties,
  MixpanelVacancyRepostProperties,
  MixpanelVacancyRepostConfirmProperties,
  MixpanelVacancyRepostFirstProperties,
  MixpanelVacancyRepostSecondProperties,
  MixpanelVacancyRepostSubmitProperties,
  MixpanelOnboardingSelfRegistrationUserProperties,
  MixpanelOnSignInUserProperties,
  MixpanelApplicantScreeningSetProperties,
  MixpanelApplicantScreeningPreviewTabProperties,
  MixpanelApplicantScreeningSetSubmitProperties,
  MixpanelVacancyCloseSurveyProperties,
  MixpanelVacancyShareProperties,
  MixpanelAccountFaqProperties,
  MixpanelAccountHelpProperties,
  MixpanelVacancyCopyProperties,
  MixpanelVacancyCopyConfirmProperties,
  MixpanelVacancyCopyFirstProperties,
  MixpanelVacancyCopySecondProperties,
  MixpanelVacancyCopyPublishProperties,
  MixpanelVacancyCopySubmitProperties,
  MixpanelVacancyCopyValidationProperties,
  MixpanelVacancyNavigationProperties,
  MixpanelVacancyRepostThirdProperties,
  MixpanelVacancyLimitProperties,
  MixpanelApplicantChangeVacancyProperties,
  MixpanelVacancyActionProperties,
  MixpanelVacancyOpenAllApplicantProperties,
  MixpanelVacancyOpenSpecificApplicantProperties,
} from '~/types/mixpanel';
import { NEW_RELIC_PAGE_ACTION_NAME } from './constants/newrelic';

// not defined current type implementation
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/mixpanel-browser/index.d.ts#L45-L88
// but error_reporter has implement as config by mixpanel browser team
// https://github.com/mixpanel/mixpanel-js/releases/tag/v2.45.0
interface MpConfig extends Config {
  error_reporter?: (msg: string, err: Error) => void;
}

// this should be invoked first before calling any other mixpanel methods
export const init = () => {
  mixpanel.init(appConfig.mixpanelToken, {
    error_reporter: (msg, err) => {
      window.newrelic?.addPageAction(
        NEW_RELIC_PAGE_ACTION_NAME.MIXPANEL_ANALYTIC,
        {
          message: msg,
          error: err,
        },
      );
    },
  } as MpConfig);
};

export const setAliasOnlyEmail = (user: MixpanelOnSignInUserProperties) => {
  const distinctId = mixpanel.get_distinct_id();
  mixpanel.alias(distinctId, user.email);
};

export const setAliasOnboardingSelfRegistration = (
  user: MixpanelOnboardingSelfRegistrationUserProperties,
) => {
  const distinctId = mixpanel.get_distinct_id();
  mixpanel.alias(user.email, distinctId);
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    userEmail: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber,
  });
};

export const setUserProperties = (user: MixpanelUserProperties) => {
  mixpanel.identify(user.id);
  // @see https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    userEmail: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber,
    companyCode: user.companyCode,
    companyName: user.companyName,
    companySize: user.companySize,
    companyIndustry: user.companyIndustry,
    companyType: user.companyType,
    userRole: user.userRole,
    companyPIC: user.companyPIC,
    companyStatus: user.companyStatus,
    companyPackage: user.companyPackage,
  });
};

export const reset = () => mixpanel.reset();

export const onboardingRegister = () => mixpanel.track('onboarding_register');

export const onboardingSelfRegistration = () =>
  mixpanel.track('onboarding_self_registration');

export const onboardingSelfRegistrationSubmit = (
  props: MixpanelOnboardingSelfRegistrationSubmitProperties,
) => mixpanel.track('onboarding_self_registration_submit', props);

export const onboardingSelfRegistrationData = () =>
  mixpanel.track('onboarding_self_registration_data');

export const onboardingSignIn = () => mixpanel.track('onboarding_sign_in');

export const vacancyCreateDashboard = () =>
  mixpanel.track('vacancy_create_dashboard');

export const vacancyCreate = () => mixpanel.track('vacancy_create');

export const vacancyCreateFirst = () => mixpanel.track('vacancy_create_first');

export const vacancyCreateSecond = () =>
  mixpanel.track('vacancy_create_second');

export const vacancyCreateThird = () => mixpanel.track('vacancy_create_third');

export const vacancyCreateSubmit = (
  props: MixpanelVacancyCreateSubmitProperties,
) => mixpanel.track('vacancy_create_submit', props);

export const vacancyUpdate = (props: MixpanelVacancyUpdateProperties) =>
  mixpanel.track('vacancy_update', props);

export const vacancyUpdateStatus = (
  props: MixpanelVacancyUpdateStatusProperties,
) => mixpanel.track('vacancy_update_status', props);

export const applicantContact = (props: MixpanelApplicantContactProperties) =>
  mixpanel.track('applicant_contact', props);

export const applicantContactCopy = (
  props: MixpanelApplicantContactProperties,
) => mixpanel.track('applicant_contact_copy', props);

export const applicantProceedSingle = (
  props: MixpanelApplicantProceedSingleProperties,
) => mixpanel.track('applicant_proceed_single', props);

export const applicantProceedBulk = (
  props: MixpanelApplicantProceedBulkProperties,
) => mixpanel.track('applicant_proceed_bulk', props);

export const applicantSearch = (props: MixpanelApplicantSearchProperties) =>
  mixpanel.track('applicant_search', props);

export const applicantFilter = (props: MixpanelApplicantFilterProperties) =>
  mixpanel.track('applicant_filter', props);

export const applicantTab = (props: MixpanelApplicantTabProperties) =>
  mixpanel.track('applicant_tab', props);

export const applicantPreview = (props: MixpanelApplicantPreviewProperties) =>
  mixpanel.track('applicant_preview', props);

export const applicantDetail = (props: MixpanelApplicantPreviewProperties) =>
  mixpanel.track('applicant_detail', props);

export const applicantRecommendationFeedback = (
  props: MixpanelApplicantRecommendationFeedbackProperties,
) => mixpanel.track('applicant_recommendation_feedback', props);

export const accountTab = (props: MixpanelAccountTabProperties) =>
  mixpanel.track('account_tab', props);

export const premiumOffering = (props: MixpanelPremiumOfferingProperties) =>
  mixpanel.track('premium_offering', props);

export const premiumOfferingSelect = (
  props: MixpanelPremiumOfferingSelectProperties,
) => mixpanel.track('premium_offering_select', props);

export const premiumOfferingSubmit = (
  props: MixpanelPremiumOfferingSelectProperties,
) => mixpanel.track('premium_offering_submit', props);

export const premiumOfferingCancel = () =>
  mixpanel.track('premium_offering_cancel');

export const onBoardingPremiumSelect = (
  props: MixpanelPremiumOfferingSelectProperties,
) => mixpanel.track('onboarding_premium_select', props);

export const onBoardingPremiumSubmit = (
  props: MixpanelPremiumOfferingSelectProperties,
) => mixpanel.track('onboarding_premium_submit', props);

export const onBoardingPremiumCancel = () =>
  mixpanel.track('onboarding_premium_cancel');

export const vacancyHighlightSelect = (
  props: MixpanelVacancyHighlightSelectProperties,
) => mixpanel.track('vacancy_highlight_select', props);

export const vacancyHighlightSubmit = (
  props: MixpanelVacancyHighlightSubmitProperties,
) => mixpanel.track('vacancy_highlight_submit', props);

export const vacancyHighlightCancel = (
  props: MixpanelVacancyHighlightCancelProperties,
) => mixpanel.track('vacancy_highlight_cancel', props);

export const vacancyUnhighlightSelect = (
  props: MixpanelVacancyUnhighlightSelectProperties,
) => mixpanel.track('vacancy_unhighlight_select', props);

export const vacancyUnhighlightSubmit = (
  props: MixpanelVacancyUnhighlightSubmitProperties,
) => mixpanel.track('vacancy_unhighlight_submit', props);

export const vacancyUnhighlightCancel = (
  props: MixpanelVacancyUnhighlightCancelProperties,
) => mixpanel.track('vacancy_unhighlight_cancel', props);

export const menuSidebarSelect = (props: MixpanelMenuSidebarSelectProperties) =>
  mixpanel.track('menu_sidebar_select', props);

export const vacancySeePreview = (props: MixpanelVacancySeePreviewProperties) =>
  mixpanel.track('vacancy_see_preview', props);

export const vacancySeePolicy = (props: MixpanelVacancySeePolicyProperties) =>
  mixpanel.track('vacancy_see_policy', props);

export const candidateVacancySelect = (
  props: MixpanelCandidateVacancySelectProperties,
) => mixpanel.track('candidate_vacancy_select', props);

export const candidateSelect = (props: MixpanelCandidateSelectProperties) =>
  mixpanel.track('candidate_select', props);

export const candidateDetail = () => mixpanel.track('candidate_detail');

export const candidateAction = (props: MixpanelCandidateActionProperties) =>
  mixpanel.track('candidate_action', props);

export const candidateActionConfirm = (
  props: MixpanelCandidateActionConfirmProperties,
) => mixpanel.track('candidate_action_confirm', props);

export const applicantAcceptSingle = (
  props: MixpanelApplicantAcceptSingleProperties,
) => mixpanel.track('applicant_accept_single', props);

export const applicantAcceptBulk = (
  props: MixpanelApplicantAcceptBulkProperties,
) => mixpanel.track('applicant_accept_bulk', props);

export const applicantRejectSingle = (
  props: MixpanelApplicantRejectSingleProperties,
) => mixpanel.track('applicant_reject_single', props);

export const applicantRejectBulk = (
  props: MixpanelApplicantRejectBulkProperties,
) => mixpanel.track('applicant_reject_bulk', props);

export const applicantDetailTab = (
  props: MixpanelApplicantDetailTabProperties,
) => mixpanel.track('applicant_detail_tab', props);

export const applicantDownloadProfile = (
  props: MixpanelApplicantDownloadProfileProperties,
) => mixpanel.track('applicant_download_profile', props);

export const applicantDownloadCV = (
  props: MixpanelApplicantDownloadCVProperties,
) => mixpanel.track('applicant_download_CV', props);

export const logout = (props: MixpanelLogoutProperties) =>
  mixpanel.track('logout', props);

export const applicantBulkProcessError = (
  props: MixpanelApplicantBulkErrorProcessProperties,
) => mixpanel.track('error_process', props);

export const applicantClosePopover = (
  props: MixpanelApplicantClosePopoverProperties,
) => mixpanel.track('applicant_close_popover', props);

export const applicantSupportLink = (
  props: MixpanelApplicantSupportLinkProperties,
) => mixpanel.track('applicant_support_link', props);

export const vacancyRepost = (props: MixpanelVacancyRepostProperties) =>
  mixpanel.track('vacancy_repost', props);

export const vacancyRepostConfirm = (
  props: MixpanelVacancyRepostConfirmProperties,
) => mixpanel.track('vacancy_repost_confirm', props);

export const vacancyRepostFirst = (
  props: MixpanelVacancyRepostFirstProperties,
) => mixpanel.track('vacancy_repost_first', props);

export const vacancyRepostSecond = (
  props: MixpanelVacancyRepostSecondProperties,
) => mixpanel.track('vacancy_repost_second', props);

export const vacancyRepostThird = (
  props: MixpanelVacancyRepostThirdProperties,
) => mixpanel.track('vacancy_repost_third', props);

export const vacancyRepostSubmit = (
  props: MixpanelVacancyRepostSubmitProperties,
) => mixpanel.track('vacancy_repost_submit', props);

export const applicantScreeningSetFilter = (
  props: MixpanelApplicantScreeningSetProperties,
) => mixpanel.track('applicant_screening_set', props);

export const applicantScreeningSetSubmitFilter = (
  props: MixpanelApplicantScreeningSetSubmitProperties,
) => mixpanel.track('applicant_screening_set_submit', props);

export const applicantScreeningPreviewTab = (
  props: MixpanelApplicantScreeningPreviewTabProperties,
) => mixpanel.track('applicant_screening_preview_tab', props);

export const vacancyCloseSurvey = (
  props: MixpanelVacancyCloseSurveyProperties,
) => mixpanel.track('vacancy_close_survey', props);

export const vacancyShare = (props: MixpanelVacancyShareProperties) =>
  mixpanel.track('vacancy_share', props);

export const accountFaq = (props: MixpanelAccountFaqProperties) =>
  mixpanel.track('account_faq', props);

export const accountHelp = (props: MixpanelAccountHelpProperties) =>
  mixpanel.track('account_help', props);

export const vacancyCopy = (props: MixpanelVacancyCopyProperties) =>
  mixpanel.track('vacancy_copy', props);

export const vacancyCopyConfirm = (
  props: MixpanelVacancyCopyConfirmProperties,
) => mixpanel.track('vacancy_copy_confirm', props);

export const vacancyCopyFirst = (props: MixpanelVacancyCopyFirstProperties) =>
  mixpanel.track('vacancy_copy_first', props);

export const vacancyCopySecond = (props: MixpanelVacancyCopySecondProperties) =>
  mixpanel.track('vacancy_copy_second', props);

export const vacancyCopyPublish = (
  props: MixpanelVacancyCopyPublishProperties,
) => mixpanel.track('vacancy_copy_publish', props);

export const vacancyCopyValidation = (
  props: MixpanelVacancyCopyValidationProperties,
) => mixpanel.track('vacancy_copy_validation', props);

export const vacancyCopySubmit = (props: MixpanelVacancyCopySubmitProperties) =>
  mixpanel.track('vacancy_copy_submit', props);

export const vacancyNavigation = (props: MixpanelVacancyNavigationProperties) =>
  mixpanel.track('vacancy_navigation', props);

export const vacancyLimit = (props: MixpanelVacancyLimitProperties) =>
  mixpanel.track('vacancy_limit', props);

export const applicantChangeVacancy = (
  props: MixpanelApplicantChangeVacancyProperties,
) => mixpanel.track('applicant_change_vacancy', props);

export const vacancyAction = (props: MixpanelVacancyActionProperties) =>
  mixpanel.track('vacancy_action', props);

export const vacancyOpenAllApplicant = (
  props: MixpanelVacancyOpenAllApplicantProperties,
) => mixpanel.track('vacancy_open_all_applicant', props);

export const vacancyOpenSpecificApplicant = (
  props: MixpanelVacancyOpenSpecificApplicantProperties,
) => mixpanel.track('vacancy_open_specific_applicant', props);
