import { gql } from '~/types/graphql/gql';

export const QUERY_JOB_VACANCY_CANDIDATES = gql(`
  query jobVacancyCandidates(
    $filter: CommonFilter
    $status: EpJobVacancyCandidateStatus
    $jobVacancyId: ID!
    $educationLevels: [String]
    $educationInstitutionIds: [ID!]
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobFunctions: [String]
    $skills: [String]
  ) {
    jobVacancyCandidates: epJobVacancyCandidates(
      filter: $filter
      status: $status
      jobVacancyId: $jobVacancyId
      educationLevels: $educationLevels
      educationInstitutionIds: $educationInstitutionIds
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobFunctions: $jobFunctions
      skills: $skills
    ) {
      elements
      page
      hasNext
      hasPrev
      list {
        id
        jobCompanyId
        jobVacancyId
        matchingResult
        userProfileId
        expectedSalaryStr
        userLastEducation {
          periodStr
          educationLevel
          educationInstitution {
            id
            name
          }
          educationProgram {
            id
            name
          }
        }
        userProfile {
          name
          imageUrl
          age
          experiencesYearStr
          city {
            name
          }
          district {
            name
          }
        }
        userLastEducation {
          educationLevel
          educationProgram {
            name
          }
        }
        userLastExperience {
          name
          companyName
          periodStr
          employmentTypeStr
        }
      }
    }
  }
`);

export const QUERY_JOB_VACANCY_CANDIDATE_SUMMARY = gql(`
  query jobVacancyCandidateSummary(
    $jobVacancyId: ID!
    $educationLevels: [String]
    $educationInstitutionIds: [ID!]
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobFunctions: [String!]
    $skills: [String!]
  ) {
    jobVacancyCandidateSummary: epJobVacancyCandidateSummary(
      jobVacancyId: $jobVacancyId
      educationLevels: $educationLevels
      educationInstitutionIds: $educationInstitutionIds
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobFunctions: $jobFunctions
      skills: $skills
    ) {
      total
      available
      invitationSent
      notInterested
    }
  }
`);

export const QUERY_JOB_VACANCY_CANDIDATE = gql(`
  query jobVacancyCandidate($id: ID!) {
    jobVacancyCandidate: epJobVacancyCandidate(id: $id) {
      id
      jobCompanyId
      jobVacancyId
      matchingResult
      matchingScore
      userProfileId
      expectedSalaryStr
      status
      userProfile {
        id
        name
        age
        experiencesYearStr
        userEmail
        nickname
        gender
        genderStr
        imageUrl
        about
        phoneNumber
        isPrivate
        birthdate
        locationId
        companyStatus
        companyNote
        updatedAt
        updatedAtStr
        educations {
          id
          userProfileId
          educationLevel
          educationInstitution {
            id
            name
          }
          educationProgram {
            id
            name
          }
          description
          startYear
          endYear
          startMonth
          endMonth
          periodStr
        }
        experiences {
          periodStr
          name
          skills
          userProfileId
          startYear
          jobFunction
          endYear
          id
          employmentTypeStr
          description
          companyName
          jvSpecializationRoleId
          jvRole
        }
        skills {
          displayName
          id
          name
        }
        links {
          id
          userProfileId
          name
          link
          supportLinkCategory {
            id
            name
            displayName
            prefix
            asset {
              id
              imageUrl
            }
          }
        }
        district {
          id
          parentId
          name
          level
        }
        city {
          id
          parentId
          name
          level
        }
        province {
          id
          parentId
          name
          level
        }
        cv {
          url
        }
      }
      userLastEducation {
        educationLevel
        educationProgram {
          name
        }
      }
      userLastExperience {
        name
        companyName
        periodStr
        employmentTypeStr
      }
      matchingResult
    }
  }
`);

export const QUERY_JOB_VACANCY_CANDIDATE_PREV_NEXT = gql(`
  query jobVacancyCandidatePrevNext(
    $id: ID
    $matchingScore: Float
    $status: EpJobVacancyCandidateStatus
    $jobVacancyId: ID!
    $educationLevels: [String]
    $educationInstitutionIds: [ID!]
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobFunctions: [String!]
    $skills: [String!]
  ) {
    jobVacancyCandidatePrevNext: epJobVacancyCandidatePrevNext(
      id: $id
      matchingScore: $matchingScore
      status: $status
      jobVacancyId: $jobVacancyId
      educationLevels: $educationLevels
      educationInstitutionIds: $educationInstitutionIds
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobFunctions: $jobFunctions
      skills: $skills
    ) {
      id
      prevId
      nextId
    }
  }
`);

export const MUTATION_DELETE_JOB_VACANCY_CANDIDATE = gql(`
  mutation deleteJobVacancyCandidate($id: ID!) {
    deleteJobVacancyCandidate: epDeleteJobVacancyCandidate(id: $id) {
      isSuccess
      data
      error
    }
  }
`);

export const QUERY_VERIFY_JOB_VACANCY_CANDIDATE_INVITATION = gql(`
  query verifyJobVacancyCandidateInvitation($id: ID!) {
    verifyJobVacancyCandidateInvitation: epVerifyJobVacancyCandidateInvitation(
      id: $id
    ) {
      templateStr
      status
      jobApplicationId
      copyStr
    }
  }
`);

export const QUERY_JOB_VACANCY_CANDIDATE_COUNT = gql(`
  query jobVacancyCandidateCount(
    $jobVacancyId: ID!
    $educationLevels: [String]
    $educationInstitutionIds: [ID!]
    $gender: String
    $ageMin: Int
    $ageMax: Int
    $name: String
    $sort: String
    $locations: [EpLocationAreaInput!]
    $minSalary: Int
    $maxSalary: Int
    $experienceYear: String
    $jobFunctions: [String]
    $skills: [String]
    $status: EpJobVacancyCandidateStatus!
  ) {
    jobVacancyCandidateCount: epJobVacancyCandidateCount(
      jobVacancyId: $jobVacancyId
      educationLevels: $educationLevels
      educationInstitutionIds: $educationInstitutionIds
      gender: $gender
      ageMin: $ageMin
      ageMax: $ageMax
      name: $name
      sort: $sort
      locations: $locations
      minSalary: $minSalary
      maxSalary: $maxSalary
      experienceYear: $experienceYear
      jobFunctions: $jobFunctions
      skills: $skills
      status: $status
    ) {
      total
    }
  }
`);

export const MUTATION_INVITE_JOB_VACANCY_CANDIDATE = gql(`
  mutation inviteJobVacancyCandidate($data: EpInviteJobVacancyCandidateInput!) {
    inviteJobVacancyCandidate: epInviteJobVacancyCandidate(data: $data) {
      isSuccess
      data
      error
    }
  }
`);
